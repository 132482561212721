/* 
Styling below is for the different colors used 
These can all be changed later on or put in separate css files if needed
*/

.navBar {
  background: var(--color1);
  height: 125px;
  /* padding: 5%; */
  z-index: 1;
}

.subNavBar {
  height: 90px;
  /* padding: 5%; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-family: Lato;
  /* font-weight: 800; */
  z-index: 2;
}

.subNavBar>.container {
  padding: 0;
}

.mr-auto {
  display: none!important;
}

.sidenav---sidenav-subnavitem---1cD47::before {
  content: none!important;
}

.sidenav---sidenav---_2tBP {
  background-color: var(--color2)!important;
  /* margin-top: 230px; */
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  width: 95vw;
  height: fit-content;
  top: 13px;
  margin-left: 2.5%;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv {
  height: 64px;
  top: 13px;
  margin-left: 2.5%;
}

.sidenav---sidenav-nav---3tvij {
  visibility: hidden;
}

.sidenav---sidenav-subnav---1EN61 {
  visibility: visible;
}

/* for side nav text */

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: white !important;
}

/* Secondary Nav menu */

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61>.sidenav---sidenav-subnavitem---1cD47>.sidenav---navitem---9uL5T {
  text-align: left;
  color: white !important;
  padding-left: 15vw !important;
}

/* Change color on navbar menu text */

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 1) !important;
  border-radius: 15px;
}

.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 1) !important;
  font-weight: 900;
  border-radius: 15px;
}

.navbar-expand .navbar-nav .nav-link {
  padding: 1em 1.5em 1em 1.5em !important;
}

#responsive-navbar-nav .nav-link{
  padding: 1em 1.5em 1em 1.5em !important;
}

#responsive-navbar-nav-fr .nav-link{
  padding: 1em 0.75em 1em 0.75em !important;
}

.navbar-collapse>div {
  padding: 0 !important;
}

.navbar>div {
  padding: 0 !important;
}

.discover {
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
}

.discover p {
  margin: 0;
}

.infoText {
  font-size: 14pt;
  align-items: center;
  color: black;
}

.dropdown-menu.show {
  width: 100%;
  padding: 0%;
}

.dropMenu {
  position: relative;
  display: inline-block;
}

.dropMenu-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  padding: .5em 1em !important;
  font-size: 16pt;
  background-color: var(--color1) !important;
}

.nav-link {
  color: black !important;
  font-size: 16pt;
  font-family: "Lato" !important;
}

.MuiMenuItem-root a {
  font-size: 16pt;
  text-decoration: none;
  color: inherit;
}

.Menu-btn {
  display: none !important;
}

.dropMenu-content>a {
  background-color: var(--color1);
  text-align: left;
}

.dropMenu:hover .dropMenu-content {
  display: block;
}

.dropMenu:hover .dropbtn {
  background-color: var(--hover-color);
  border-radius: 15px;
}

.subNavBar a:hover {
  background-color: var(--hover-color);
  border-radius: 15px;
}

.translateBtn {
  background-color: white !important;
  color: black !important;
  border: none !important;
  font-size: 14px !important;
  padding: 1em !important;
}

.translateBtn:hover {
  background-color: var(--hover-color) !important;
}

.MuiPopover-paper {
  width: 100%;
  max-height: 100%;
}

@media (max-width: 1400px){
  #responsive-navbar-nav .nav-link{
    padding: 1em 1em 1em 1em !important;
  }
  
  #responsive-navbar-nav-fr .nav-link{
    padding: 1em 0.3em 1em 0.3em !important;
  }
}

@media only screen and (min-width: 1200px) {
  .navBar {
    display: flex!important;
  }
  .mr-auto {
    display: flex!important;
  }
  .sidenav---sidenav---_2tBP {
    display: none!important;
  }
  .discover {
    padding: 15px;
  }
  
}

@media(max-width:768px) {
  .infoText {
    text-align: left;
  }
  .navBar>.container {
    display: block !important;
  }
  .infoText>.col-4 {
    padding: 0em 1.5em 0em 1.5em;
  }
  .infoText {
    font-size: 8pt;
    text-align: center;
  }
  .subNavBar{
    padding: 5%;
  }
}

@media(max-width:1200px) {
  .Menu-btn {
    display: flex !important;
    padding: 0 !important;
    min-width: 0 !important;
  }
}