.infoContainer {
    /* margin-bottom: 4rem; */
    margin-bottom: var(--container-margin);
}

.bookSpeaker {
    color: black;
    background-color: white;
    width: 90% !important;
    border-color: black;
    border: 1px solid rgba(0, 0, 0, .125);
    padding: 3em !important;
}

.bookSpeaker img {
    max-width: auto;
}

.bookSpeaker h1 {
    padding-top: 0rem;
    text-align: left;
    /* padding-left: 2.5em; */
}

.bookSpeaker p {
    text-align: left;
    padding: 0 !important;
}

.bookSpeaker-content {
    padding: 3rem !important;
}

.bookSpeaker-img {
    width: 100% !important;
}

.card-img-top {
    padding: 2em;
}

#accordion {
    padding: 0 5% 0 5%;
}

#accordion p {
    padding: .5em 3em .5em 3em;
    text-align: left;
}

/* .btn-send{
    float:left;
    margin-left: 0;
} */

.contactForm {
    color: black;
    background-color: white;
    width: 90% !important;
    border-color: black;
    border: 1px solid rgba(0, 0, 0, .125);
    margin-top: 50px;
}

.contactForm img {
    max-width: auto;
    padding: 2rem;
}

.contactForm h1 {
    padding-top: 0rem;
    text-align: left;
    padding-left: 2.5em;
}

.contactForm p {
    text-align: left;
    padding-left: 4rem;
    padding-right: 0;
    font-size: 14px;
}

.contactForm-content {
    padding: 3rem !important;
}

.contactForm-content .btn {
    float: left;
    width: 100px;
    border: 1px solid black !important;
    color: black !important;
}

.contactHead{
    padding: 1em 0em .5em 0em !important;
}

.serviceContainer{
    margin-top: var(--container-margin);
}
.serviceContainer-premium{
    margin-top: 0;
    margin-bottom: var(--container-margin);
    transition: all .25s linear;
    /* transition: max-height 1s linear; */
    display: block;
    max-height: 100%;
}

.infoContainer.hidden{
    
}

.hidden{
    display: none;
    /* max-height: 0%; */
}

.visuallyhidden{
    opacity: 0;
}

.faqContainer p{
    padding: 0em 2em;
}

#edu-player {
    filter: drop-shadow(10px 8px 0px var(--color2)) !important;
    padding: 0em;
}

@media(max-width: 768px) {
    #accordion p {
        padding: .5em 1em;
    }
    .contactForm-content {
        padding: 0.5em !important;
    }
    .contactForm-content .btn {
        width: 100%;
    }
    .bookSpeaker-content {
        padding: 0rem !important;
    }
    .bookSpeaker-content h1{
        padding: 0rem !important;
    }
    .faqContainer p{
        padding: 0em 0em;
    }
}