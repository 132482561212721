/* 
Following 3 are styling for the footer elements
*/

.footerCard {
  float: left;
  padding-right: 3%;
  padding-left: 3%;
}

.footerCard h1 {
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  /* margin-bottom: 20%; */
}

.footerCard h1 {
  text-align: left;
  font-size: 14px;
  font-weight: 800;
  /* margin-bottom: 20%; */
}

.footerCard p {
  font-size: 11pt;
  text-align: left;
  margin-top: 10%;
  margin-bottom: 0%;
}

.footerCard a {
  color: inherit;
  text-decoration: none;
}

.primaryElement #footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0em 5em 0em 5em !important;
}

.footer {
  padding: 0em 5em 0em 5em !important;
  background-color: var(--color1);
}

#footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2.5em 1em 2.5em 1em;
}

/* Embedded mailing list subscribe id selector*/
#mc_embed_signup h2 {
  color: white;
  font-size: calc(13px + 2vmin);
  display:inline;
  padding-right:2em;
  font-weight: 900;
}
#mc_embed_signup div{
  display:inline;
}
#mc_embed_signup input[type=email] {
  /* margin-right:3em; */
  margin-right: calc(100vw/33);
}

/* 
This is to be used for text information stored in card style, with elements on the left
*/

.cardStyle1 {
  float: left;
  padding-right: 20%;
}

/* 
Paragraph element inside of "cardStyle" class styling
*/

.cardStyle1 p {
  font-size: 14pt;
  text-align: left;
  color: black;
}

.cardStyle1 img {
  display: block;
}

.copyright {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 11px;
  background: var(--color1);
  height: 8vh;
}

.copyright p {
  padding-top: 2em;
  float: left;
  font-size: 8pt;
}

#btn-footer {
  padding: 0.5em !important;
  float: left;
  margin: 20px 0px 0px 0px;
  font-size: 13pt !important;
}

#btn-footer2 {
  text-decoration: none;
  background-color: none;
  color: black;
  font-size: 11pt;
}

.toTop:hover {
  color: rgb(88, 166, 255);
  cursor: pointer;
}

#footerMenu {
  text-align: left;
  display: block;
}

.btn-social {
  padding: 30px 0px 30px 0px;
  background-color: var(--color1);
}

.btn-social img {
  padding: 0em 1em 0em 1em;
  width: auto;
  max-height: 45px;
}

.subscribeTxt{
  font-size: 25pt;
}

.subscribeTxt-fr{
  font-size: 22pt;
}

@media(max-width: 576px) {
  .footer {
    padding: 0em 1em !important;
  }
  .copyright>p {
    width: 50%;
    line-height: 18px;
  }
}

/*
  Dynamic changing footer based on website width
  The 1500 and 1350 is just for a button scaling fix  
*/

@media (max-width: 1500px) {
  #btn-footer {
    font-size: 11pt !important;
  }
}

@media (max-width: 1400px) {
  .subscribeTxt{
    font-size: 2rem;
  }
  .subscribeTxt-fr{
    font-size: 1.75rem;
  }
}

@media (max-width: 1350px) {
  #btn-footer {
    font-size: 9pt !important;
  }
}

@media (max-width: 1200px) and (min-width:992px) {
  #mc_embed_signup input[type=email] {
    /* margin-right:3em; */
    margin-right: calc(100vw/100);
    width: 50%;
  }
  .subscribeTxt{
    font-size: 1.75rem;
  }
  .subscribeTxt-fr{
    font-size: 1.5rem;
  }
}

@media (max-width: 991px){

}

@media (max-width: 768px) {
  .footerCard {
    float: left;
    padding-right: 1%;
    padding-left: 10%;
  }
  .footerCard p {
    font-size: 10pt;
  }
  #btn-footer2 {
    font-size: 10pt;
  }
  .cardStyle1 {
    float: none;
    padding-right: 0%;
  }
  /* 
  Paragraph element inside of "cardStyle" class styling
  */
  .cardStyle1 p {
    font-size: 12pt;
    text-align: center;
  }
  .cardStyle1 img {
    display: inline;
  }
  /* Mailing List Subscription Mobile Styles */
  #mc-embedded-subscribe, #mce-EMAIL {
    margin-top:1em;
  }
}
@media (max-width: 450px) {
  #mc_embed_signup h2, #mce-EMAIL{
    margin-left:0em;
  }
  #mc_embed_signup input[type=email] {
    margin-right:0em;
  }
}