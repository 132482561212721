h1 {
  font-weight: 800 !important;
  /* font-size: 10px; */
}

/* Used for main components on pages */

.sect1 {
  /* padding-left: 10%; */
  /* padding-right: 10%; */
}

.sect1 .primaryElement {
  padding-bottom: 0%;
}

.sect1txt {
  text-align: left;
  padding-top: 5em;
  float: left;
}

.sect1txt h1 {
  font-weight: 800;
  /* font-size: calc(1.375rem + 1.5vw) */
  font-size: 36pt;
}

.sect1 img {
  max-width: 100%;
  max-height: 100%
}

.headerElement {
  padding: 0em 0em 0em 0em !important;
}

/* 
Page Specific info container styling
*/

.infoContainer {
  bottom: -40%;
  /* height: 20em; */
}

.sect2 {
  /* overflow: auto; */
  margin-bottom: 2em;
  /* padding-left: 15%; */
  /* padding-right: 15%; */
  /* text-align: right; */
  align-items: center;
}

.sect2 h1 {
  font-weight: 700;
  font-size: 24pt;
  /* padding-top: 2rem; */
  padding-bottom: 0rem;
  /* padding-left: 2rem;
  padding-right: 2rem; */
  text-align: left;
}

.sect2 p {
  /* padding-left: 2rem;
  padding-right: 2rem; */
  /* padding-bottom: 2rem; */
  text-align: left;
  line-height: 1.5em;
}

.sect2-txt{
  padding: 0em 0em 0em 3em !important;
}

.sect3 {
  background-color: var(--color2);
  color: white;
  text-align: center;
  height: auto;
}

.sect3 h1 {
  padding-top: 2em;
  text-align: center;
}

.sect3 p {
  text-align: center;
  width: 100%;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}

.sect3 .card {
  display: block !important;
}

.shape1 {
  border-radius: 2px;
  /* transform: rotate(45deg); */
  height: 200px;
  width: 200px;
}

.shape1 img {
  /* transform: rotate(-45deg); */
  margin: auto;
  left: 0;
}

#client h1 {
  text-align: center;
  padding: 1em 0em 0em 0em;
}

.cookieConsent {
  background: var(--color2) !important;
}

#cookieTxt {
  color: lightblue;
}

#cookieTxt:hover {
  cursor: pointer;
  color: rgb(173, 198, 230);
}

.popup-innovEdu-header {
  background-color: var(--color1) !important;
}

.popup-innovEdu-header.modal-header {
  padding: 3em 3em 1em 3em !important;
}

.popup-innovEdu-body {
  background-color: var(--color1) !important;
  padding: 3em 3em 3em 3em !important;
}

.popup-innovEdu-body p {
  /* padding: 0em 0em 0em 0em; */
}

.popup-innovEdu-footer {
  background-color: var(--color1) !important;
}

.card-img-top {
  /* padding: 1em !important; */
}

.primaryElement-img {
  display: flex;
  justify-content: center;
}

.primaryElement-img>img {
  object-fit: cover;
  width: 90%;
  max-height: 100%;
}

#innov-edu-img{
  max-width: 70%;
}

.player {
  padding: 0em 0em 0em 0em;
}

.carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}

.carousel-postTxt{
  max-height: 145px;
  overflow: hidden;
}

@media(max-width:768px) {
  .card-img-top {
    /* padding: 0em !important; */
  }
  .primaryElement-img {
    padding: 0em 0em 2em 0em;
  }
  .cookieConsent {
    display: block !important;
  }
  #btn-consent{
    margin-top: 0 !important;
  }
  .headerElement {
    padding: 0em 0em 0em 0em !important;
  }
  .sect2 h1{
    font-size: 22pt;
  }
}

@media(max-width: 992px) {
  .sect1txt {
    text-align: center;
    padding-top: 0em;
    float: center;
  }
  .card-group {
    display: block !important;
  }
  .sect2-txt{
    padding: 0em 2em 0em 2em !important;
  }
}

@media(max-width: 1200px) {
  .sect1txt {
    padding-top: 0em;
  }
}