.loginContainerWithBorder, .registerContainerWithBorder{
    border: 1px solid black;
    border-radius: 5px;
    /* text-align:left !important; */
    padding: 3rem;
    margin-top: 25px;
    margin-bottom: 25px;
    color: black;
    background-color: white;
    width: 50%;
}

.bottomSection{
    margin: 1rem 0rem 0rem 0rem;
}
.signUpLbl{
    color: black;
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;
}
.loginIcon{
    width:40px;
    height:40px;
    margin-right:10px;
}
.loginBtn{
    width:150px;
    margin:1rem;
    background-color: #E4F0FE !important;
    border: 1px solid black;
    color: black !important;
}

@media(max-width: 768px){
    .loginContainerWithBorder, .registerContainerWithBorder{
        width: 90%;
        padding: 2em;
    }
}