@import url(./styling/Navbar.css);
@import url(./styling/Footer.css);
@import url(./styling/Home.css);
@import url(./styling/FeaturedBlog.css);

p {
  font-size: 16pt;
  line-height: 28px;
}

.App {
  text-align: center;
  background: #FFFFFF;
  font-family: Lato !important;
  overflow: hidden;
  /* Hide scrollbars */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
  --color1: #E4F0FE;
  --color2: #2D487C;
  --color3: #9EBDE0;
  --hover-color: #9EBDE0;
  --container-margin: 50px;
}

.button {
  background-color: var(--color1) !important;
  border: solid 1px black !important;
  border-radius: 5px !important;
  color: black !important;
  font-size: 16px !important;
  padding: 1em !important;
  filter: drop-shadow(5px 5px 0px rgb(165, 165, 165));
}

.button-small {
  background-color: var(--color1) !important;
  border: solid 1px black !important;
  color: black !important;
  font-size: 16px !important;
  padding: .5em !important;
  filter: drop-shadow(5px 5px 0px rgb(165, 165, 165));
}

.btn-2 {
  background-color: var(--color2) !important;
  color: white !important;
}

.btn-2:hover {
  color: black !important;
}

.button:hover {
  background-color: var(--color3) !important;
}

.button-small:hover {
  background-color: var(--color3) !important;
}

/* 
the following lines import the font for use
something to note is that the Black is thicker than the Bold, this is probably how the font was designed
*/

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Regular.ttf);
  font-style: normal;
}

/* 
Use 700 for bold
Use 800 for extra bold
*/

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Black.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-Italic.ttf);
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Lato;
  src: url(./fonts/Lato-BlackItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

/* 
The primary is meant to be used for elements on the page with the primary color
which is the same color as the Navbar
*/

.primaryElement {
  padding: 5em 1em 5em 1em !important;
}

.primaryElement-video {
  padding: 5em 0em 5em 0em !important;
}

.primaryElement h1 {
  margin-top: 0;
}

.primaryElement-Var1 {
  background: white;
  /* width: 60vw !important; */
  text-align: left;
}

.primaryElement-Var2 {
  background: var(--color1);
  /* width: 60vw !important; */
  padding-left: 5em;
}

.primaryElement-footer {
  background: var(--color1);
}

/* 
the boxes with cards and info 
Since these may be reused later, they stay in the main css
There are other elements like "bottom" and "height" that should be added to page specific styling - see home.css for example
*/

.infoContainer {
  margin-top: 3em;
  color: white;
  background-image: linear-gradient(var(--color2) 0%, var(--color2) 50%, white 50%, white 100%);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.infoContainer h1 {
  padding: 1em 0em;
  font-weight: 700;
  font-size: 32pt;
  margin: 0;
}

.infoContainer p {
  /* padding: 0%; */
  /* padding-left: 20%; */
  /* padding-bottom: 1em; */
}

.infoContainer-description {
  padding: 0em 5em;
}

.infoCard {
  color: black;
  margin: 0em 1em 1em 1em !important;
  border: 1px solid rgba(0, 0, 0, 1) !important;
}

.infoCard p {
  /* font-size: 11px; */
  line-height: 28px;
  padding-right: 5%;
  padding-left: 5%;
}

.infoCard h1 {
  font-size: 20px;
  line-height: 18px;
  padding: .5em;
}

.infoCard-blog {
  color: black;
  margin: 1em !important;
  border: 1px solid rgba(0, 0, 0, 1) !important;
  max-width: 32%
}

.infoCard-blog p {
  /* font-size: 11px; */
  line-height: 28px;
  padding-right: 5%;
  padding-left: 5%;
}

.infoCard-blog h1 {
  font-size: 20px;
  line-height: 18px;
  padding: .5em;
}

/* Embedded mailing list subscribe id selector*/
#mc_embed_signup {
  /* background-color: var(--color2); */
  /* padding-block: 2em; */
  /* display:flex; */
  /* justify-content:space-evenly; */
  /* align-items:center; */
  white-space: normal;
}

#mc_embed_signup .button {
  padding: .5em !important;
}

#mce-EMAIL {
  padding: .5em !important;
}

.player {
  filter: drop-shadow(25px 15px 0px var(--color2));
}

/* for use to make clickable text */

.btn-link {
  background-color: transparent;
  border: none;
}

.mail-input {
  display: flex;
  align-items: center;
}

.mail-input p {
  font-weight: 800;
}

.main-btn {
  margin-bottom: 0;
}

#sub-txt {
  margin: 0;
  width: 45%;
}

#sub-txt2 {
  margin: 0;
  width: 80%;
}

#sub-bar {
  margin: 0;
  width: 40%;
}

#sub-btn {
  width: 10%;
}

.img-center-size {
  max-width: 100%;
  /* height: 100%; */
  /* padding: 1em; */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
  margin-bottom: 2em;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  filter: drop-shadow(25px 15px 0px var(--color2));
}

.card-footer {
  background-color: white !important;
  border: none !important;
  padding: 1em 2em 2em 2em !important;
}

.popup-header {
  font-weight: 800 !important;
  font-family: Lato !important;
  font-size: 24pt !important;
}

.popup-dialog-size {
  max-width: 60% !important;
}

.card-img-dropshadow {
  filter: drop-shadow(15px 0px 0 var(--color2));
  /* max-width: 100%; */
  /* width: auto; */
  max-height: 100%;
}

.card-img-dropshadow2 {
  filter: drop-shadow(15px 0px 0 var(--color1));
  /* max-width: 100%; */
  width: auto;
  max-height: 100%;
}

.card-img-dropshadow-heavy {
  filter: drop-shadow(1.25em 0em 0 var(--color2));
  width: 100%;
  max-height: 100%;
}

.card-img-dropshadow-heavy2 {
  filter: drop-shadow(1.25em 0em 0 var(--color1));
  width: auto;
  max-height: 100%;
}

.card-img-dropshadow-img {
  filter: drop-shadow(1em 1em 0 var(--color2));
  width: auto;
  max-height: 100%;
}

.MuiDrawer-paper>div {
  position: relative !important;
}

/* 
.RaSidebar-fixed-36 {
  position: relative !important;
}

.RaSidebar-fixed-112 {
  position: relative !important;
} */

.ql-snow .ql-tooltip {
  position: static !important;
}

.MuiAppBar-root {
  display: none !important;
}

#text-element-about {
  padding: 2em !important;
}


.mailingList {
  background-color: var(--color2);
  padding: 2em 0em;
  color: white;
}

video[poster] {
  object-fit: cover;
}

.TermAndPolicy {
  text-align: left;
  margin: 3em 0em;
}

.TermAndPolicy h2 {
  font-weight: 700;
}

/* The following are for the font sizes on admin panel */

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: 'Small: 12pt' !important;
  font-size: 12pt !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before {
  content: 'Smll: 12pt' !important;
}


.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: 'Normal: 16pt' !important;
  font-size: 16pt !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: 'Nrm: 16pt' !important;
}


.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: 'Large: 24pt' !important;
  font-size: 24pt !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before {
  content: 'Lrg: 24pt' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: 'Huge: 32pt' !important;
  font-size: 32pt !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before {
  content: 'Huge: 32pt' !important;
}

.ql-size-small {
  font-size: 12pt;
}

.ql-size-large {
  font-size: 24pt;
}

.ql-size-huge {
  font-size: 32pt;
}

@media(max-width:768px) {
  .primaryElement-Var2 {
    background: var(--color1);
    /* width: 60vw !important; */
    padding: 3em 3em 3em 3em !important;
    padding-left: 0;
  }

  #sub-txt {
    margin: 0;
    width: 100%;
  }

  #sub-txt2 {
    margin: 0;
    width: 100%;
  }

  #sub-bar {
    margin: 0;
    width: 100%;
  }

  #sub-btn {
    padding: 1em 0em 0em 0em;
    width: 100%;
  }

  .infoContainer h1 {
    padding: 1em .5em;
    font-size: 26pt;
  }

  .infoContainer p {
    padding: 0em .5em;
  }

  .infoContainer-description {
    padding: 0em 1em;
  }

  .infoCard h1 {
    line-height: 30px;
  }
}


@media(max-width:991.5px) {
  .align-items-center>#text-element {
    order: 2;
  }

  .align-items-center>#image-element {
    order: 1;
  }

}

@media(max-width:1000px) {
  .MuiAppBar-root {
    display: flex !important;
    position: relative !important;
    width: 100vw !important;
  }

  .primaryElement {
    padding: 2em 0em 2em 0em !important;
  }

  .infoCard-blog {
    margin: 1em 0em !important;
    max-width: 100%
  }

  .primaryElement-video {
    padding: 1em 1em 1em 1em !important;
  }

  .popup-dialog-size {
    max-width: 100% !important;
  }

  /* Embedded mailing list subscribe id selector*/
  #mc_embed_signup {
    padding-block: 0em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.ql-color .ql-picker-options [data-value=custom-color]:before {
  content: 'Pick Color';
  background-color: none !important;
  border-color: none !important;
}

.ql-color .ql-picker-options [data-value=custom-color] {
  background: none !important;
  width: 100% !important;
  height: 25px !important;
  text-align: center;
  color: blue;
  text-decoration: underline;
}

input[type="color"] {}