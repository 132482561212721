.blogContainer {
  font-family: Lato;
  text-align: left;
  letter-spacing: 0em;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.featuredPostImg {
  width: 100%;
  height: auto;
  border: 1px solid;
  box-shadow: 15px 15px #2d487c;
}
.infoContainer {
  text-align: center;
}

.sectionContainer {
  margin-top: 50px;
  padding-left: 4em;
  padding-right: 4em;
}

#featuredPostContainer p {
  margin: 0;
  padding-left: 0px;
}

/* Make Card image responsive */

.col-6, .col-md-4 {
  margin-bottom: 20px;
}

.titleSection-blog {
  color: black;
  font-size: 2rem;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.005em;
}

.featuredPostContent p {
  padding: 0;
}

.featuredPostContent button {
  margin-right: 20px;
}

.featuredPostSubtitle {
  font-size: 18pt;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  color: #4c4c4c;
  padding-bottom: 0;
}

.featuredPostLead {
  font-family: Lato;
  line-height: 19px;
  color: #4c4c4c;
}

.featuredPostDescription {
  line-height: 28px;
  max-height: 280px;
  overflow: auto;
  text-overflow: ellipsis;
}

.featuredPostDescription img{
  max-width: 100%;
}

.featuredPostDescription::-webkit-scrollbar{
  display: none;
}

.featuredPostLead span::after {
  content: "";
  margin: 0 10px 0 10px;
  border: 1px solid;
  border-width: 0 0 0 1px;
}

.categoriesFilter {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0em;
}

.categoryLink {
  text-decoration: none;
  color: black;
}

.categoriesContainer {
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 5%;
}

/* Post Cards */

.postContainer {}

.blogPostCard {
  text-align: left;
  margin: 1rem;
  border: 1px solid rgba(0, 0, 0, 1) !important
}

.blogPostCard a:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  color: black;
}

.blogPostCard a {
  color: inherit;
  text-decoration: none;
}

.blogPostCard .card-body {
  padding: 0;
}

.blogPostCard .card-img-top {
  /* padding: 0; */
}

.blogPostCard .card-text {
  padding: 1rem 1rem;
}

.blogPostCard .card-footer {
  background-color: inherit;
  border-top: none;
}

.blogPostCard-body {
  padding: 0em 2em;
}

.blogPostCard-title {
  padding: 0em 1.5em;
}

.headerTitle {
  font-size: 42px;
  font-style: normal;
  line-height: 55px;
  letter-spacing: 0.005em;
  text-align: center;
}

.categoryLinks {
  font-family: Lato;
  text-align: center;
}

.featured-btn {
  font-size: 10pt !important;
}

.categoryLinkClicked {
  color: red;
}

.categoriesFilterName {
  font-weight: 900;
}

.headerLead{
  padding: 0em 6em !important;
}

@media(max-width: 991px) {
  .blogPostCard {
    margin: 1em 0em;
  }
  .featured-btn {
    margin: .5em 0em;
  }
  .headerLead{
    padding: 0em 1em !important;
  }

  .titleSection-blog{
    padding: 1em 0em 0em 0em;
  }
  
}