.infoContainer {
  text-align: center;
}

.sectionContainer {
  margin-top: 50px;
  padding-left: 4em;
  padding-right: 4em;
}

#featuredPostContainer p {
  margin: 0;
  padding-left: 0px;
}

/* Make Card image responsive */

.col-6, .col-md-4 {
  margin-bottom: 20px;
}

.blogPostContent p {
  padding: 0;
}

.blogPostImg {
  width: 250px;
}

.blogPostContent {
  margin-top: 25px !important;
}

.blogPost-title {
  font-style: normal;
  font-weight: 900;
  padding: 0 !important
}

.featuredPostLead {
  font-family: Lato;
  line-height: 24px;
  color: #4c4c4c;
}

.featuredPostLead span::after {
  content: "";
  margin: 0 10px 0 10px;
  border: 1px solid;
  border-width: 0 0 0 1px;
}

.categoriesFilter {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0em;
}

.categoryLink {
  text-decoration: none;
}

.categoriesContainer {
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 5%;
}

/* Post Cards */

.postContainer {}

.postSidebar {
  border-left: #7a7a7a 1px solid;
  padding: 1em 1em 1em 1em !important;
  margin: 3em 0em !important;
}

.postSidebar col {
  align-items: center;
}

/* .postSidebar:after{
  content: "";
  background: black;
  position: relative;
  bottom: -195%;
  left: 10%;
  height: 250%;
  width: 1px;
} */

.postSidebarTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 18pt;
  text-align: left;
  padding: 0em 0em .5em 0em !important;
  margin: 0;
}

.recentPostTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 16pt;
  text-align: left;
  line-height: 16pt;
  margin: 0;
}

.recentPostLink {
  text-decoration: none !important;
  color: inherit;
}

.postSidebarLead {
  font-style: normal;
  font-weight: normal;
  font-size: 14pt;
  color: #000000;
  text-align: left;
}

.featuredPostImg-sideBar {
  width: 100%;
  height: auto;
  border: 1px solid;
  box-shadow: 7px 7px #2d487c;
  margin: 1em 0em;
}

.blogPostContainer {
  margin-top: 3em;
  color: white;
  background-image: linear-gradient(var(--color2) 0%, var(--color2) 50%, white 50%, white 100%);
  text-align: center;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

#header-txt {
  padding: 0% 20% 0% 20%;
}

.blogPostContainer h1 {
  padding-top: 1.5em;
  font-weight: 700;
  /* font-size: 26px; */
}

.categoryList {
  list-style-type: none;
  text-align: left;
  padding: 0;
}

.categoryListItem a {
  text-decoration: none;
  color: black;
  /* font-weight: bold; */
}

.blogPost-Description {
  overflow: hidden;
}

.blogPost-Description a {
  color: #2D487C !important;
}

.blogPost-Description h3 {
  /* border-top: #7a7a7a solid 1px; */
  padding: 1em 0em 0em 0em;
  width: 500%;
}

.blogPost-Description li{
  font-size: 16pt;
}

.blogPost-Description img{
  max-width:100%;
}

.blog-content {
  padding: 3em 3em 3em 3em !important;
}

.blog-content a {
  color: black;
  text-decoration: none;
}

.prev-btn {
  float: left;
  text-align: left;
}

.prev-btn p {
  margin: 0;
}

.prev-btn .col-lg-auto {
  padding: 0;
}

.prev-btn-bottom {
  text-align: left;
  margin: 0 !important;
}

.next-btn {
  text-align: right;
  float: right;
}

.next-btn p {
  margin: 0;
}

.next-btn .col-lg-auto {
  padding: 0;
}

.next-btn-bottom {
  text-align: right;
  margin: 0 !important;
}

.author-row {
  padding: 2em 0em;
}

.author-text {
  padding-left: 2em !important;
}

.author-text>h1 {
  padding: 0;
  font-size: 24pt;
}

.author-text>h2 {
  padding: 0;
  font-size: 20pt;
}

.postSidebar input {
  max-width: 100%;
  margin: .5em 0em;
}

.react-player-blog {
  position: relative;
  height: 500px;
  /* top: 0; */
  /* left: 0; */
  /* filter: drop-shadow(25px 15px 0px var(--color2)); */
}

#share-button button{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#author-button{
  padding: 0.5em !important;
  margin-left: 1em;
}

#button-side{
  padding: 0.5em !important;
}

.sidebar-category{
  /* overflow: auto; */
  /* white-space: initial; */
  word-wrap: break-word;
}

@media(max-width: 991px) {
  .postSidebar {
    border-left: black 0px solid;
    padding: 0em 1em 3em 1em !important;
  }

  .postSidebarLead {
    text-align: left;
  }

  #header-txt {
    padding: 0em 1em;
  }

  .blog-content {
    padding: 1em 1em 1em 1em !important;
  }

  .prev-btn {
    text-align: center;
  }

  .next-btn {
    text-align: center;
  }

  .postSidebar {
    margin: 0em !important;
  }

  #author-img {
    padding: 2em;
  }
}