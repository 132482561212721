.socialMedia{
    background-color: var(--color1);
    padding: 5em 8em 5em 8em;
}

.socialMedia h1{
    text-align: center;
}

.socialMedia p{
    text-align: LEFT;
    margin-bottom: 0%;
}

.socialMedia img{
    max-width: 75px;
    max-height: 75px;
    /* padding-bottom: 2rem; */
}

.socialCard{
    background-color: var(--color1);
}

.socialCard img{
    width: auto;
    max-height: 10rem;
}

.card-body{
    /* height: 10rem; */
    width: auto;
}

.socialContent{
    max-width: 100%;
}

.socialContent p{
    margin-top: 1em;
}

.socialContent-Media{
    height: 212px;
}

/* .socialMedia p{
    text-align: left;
} */

.socialMedia .col{
    padding: 2em;
}

.MuiCardMedia-root .socialContent-Media{
    background-size: contain;
    resize: both;
}

#keepConnected{
    padding: 0em 0em 1em 0em;
    font-size: 24pt;
    margin: 0;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding: 1em 2em;
}

.card>img{
    max-width: 100%;
    max-height: 100%;
    padding: 0;
}

#video-img{
    max-width:100%;
    max-height:100%;
}

@media(max-width: 768px){
    .socialMedia{
        background-color: var(--color1);
        padding: 5em 1.5em 5em 1.5em;
    }
}