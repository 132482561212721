* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --slider-width: 70vw;
    --slider-height: 15vw;
    --slider-elem: 21;
    --slider-elemDisp: 5;
    --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp));
    --slider-animDuration: calc(var(--slider-elem)*2.5s);
}

#client {
    --slider-color: white;
    background-color: var(--slider-color);
    --slider-elem: 9;
    --slider-elemDisp: 4;
    --slider-animDuration: calc(var(--slider-elem)*2.5s);
    --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp));
}

#partner {
    --slider-color: white;
    background-color: var(--slider-color);
    --slider-elem: 13;
    --slider-elemDisp: 4;
    --slider-animDuration: calc(var(--slider-elem)*2.5s);
    --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp));
}

#speakEng {
    --slider-color: var(--color1);
    background-color: var(--slider-color);
    --slider-height: 20vw;
    --slider-elem: 34;
    --slider-elemDisp: 4;
    --slider-animDuration: calc(var(--slider-elem)*2.5s);
    --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp));
}

.slider {
    width: var(--slider-width);
    height: var(--slider-height);
    overflow: hidden;
    position: relative;
    margin: auto;
    /* box-shadow: 0 10px 20px -5px rgba(0,0,0,5); */
}

.slider:before, .slider:after {
    position: absolute;
    top: 0;
    content: '';
    width: 12rem;
    height: 100%;
    z-index: 1;
}

.slider:before {
    left: 0;
    background: linear-gradient(to right, var(--slider-color) 0%, rgba(255, 255, 255, 0) 100%);
}

.slider:after {
    right: 0;
    background: linear-gradient(to left, var(--slider-color) 0%, rgba(255, 255, 255, 0) 100%);
}

.slider-content:hover {
    animation-play-state: paused;
}

.slider-content {
    list-style: none;
    height: 100%;
    display: flex;
    animation: scrolling var(--slider-animDuration) linear infinite;
}

@keyframes scrolling {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-1*(var(--slider-elemWidth))*var(--slider-elem)));
    }
}

.slider-content li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--slider-elemWidth);
    flex-shrink: 0;
    white-space: nowrap;
}

@media(max-width:1000px) {
    #client {
        --slider-height: 20vh;
        /* --slider-elem: 9; */
        --slider-elemDisp: 2;
        /* --slider-animDuration: calc(var(--slider-elem)*2.5s); */
        --slider-width: 80vw;
        /* --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp)); */
    }
    #partner {
        --slider-height: 20vh;
        --slider-elemDisp: 2;
        /* --slider-elem: 13; */
        /* --slider-animDuration: calc(var(--slider-elem)*2.5s); */
        --slider-width: 80vw;
        /* --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp)); */
    }
    #speakEng {
        --slider-height: 20vh;
        --slider-elemDisp: 2;
        /* --slider-elem: 34; */
        /* --slider-animDuration: calc(var(--slider-elem)*2.5s); */
        --slider-width: 80vw;
        /* --slider-elemWidth: calc(var(--slider-width)/var(--slider-elemDisp)); */
    }
    /* @keyframes scrolling {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-1*var(--slider-elemWidth)*var(--slider-elem)));
        }
    } */
    /* .slider {
        width: var(--slider-width);
        color: var(--slider-height);
        height: var(--slider-height);
        overflow: hidden;
        position: relative;
        margin: auto; */
        /* box-shadow: 0 10px 20px -5px rgba(0,0,0,5); */
    /* } */
    .slider:before, .slider:after {
        width: 5rem;
    }
}