.featured-cardgroup {
    padding: 2em;
    margin: 0% 10%;
    max-height: auto;
}

.featured-cardgroup-blog {
    padding: 2em;
    /* margin: 0% 10%; */
    max-height: auto;
}

.featured-cardgroup img {
    max-width: 300px;
}

.featured-cardgroup h1 {
    margin: 0;
    padding: 1em 0em 1em 0em !important;
}

.featured-cardgroup p {
    padding: 0 !important;
    font-size: 16pt;
}

#featuredBlog {
    background-color: var(--color1);
}

#featuredBlog h1 {
    text-align: center;
    padding: 2em 0em 0em 0em;
}

#featuredBlog p {
    text-align: center;
    width: 100%;
    padding: 0% 20%;
    margin: 0;
}

.featuredPostDescription-home {
    line-height: 28px;
    max-height: 175px;
    overflow: auto;
    text-overflow: ellipsis;
    text-align: left !important;
}

.featuredPostDescription-home p{
    text-align: left !important;
    
}

.featuredPostDescription-home::-webkit-scrollbar {
    display: none;
}