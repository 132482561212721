.checkList {
  display: block;
  /* color: #2d487c; */
}

.titleSection {
  color: #2d487c;
  font-size: 2rem;
  font-weight: 900;
  line-height: 35px;
  letter-spacing: 0.005em;
  text-align: left;
}

.aboutText{
  padding: 0.5em 0em;
}

.titleSection2 {
  text-align: center;
  padding: 50px 20px 50px 20px;
}

.titleSection2 label, .connectWithUsContainer label {
  font-size: 3rem;
  /* color: #2d487c; */
  font-weight: 900;
  margin-bottom: 8px;
}

.checkIcon {
  margin-right: 10px;
}

.checkList>ul {
  padding: 0em 1em;
  font-size: 16pt;
  list-style-image: url(check.svg);
  color: var(--color2);
}

.sectionContainer {
  padding: 1.5em 5em 1.5em 5em !important;
}

.sectionContainer p {
  text-align: left;
  width: 100%;
}

.sectionContainer h1 {
  text-align: left;
}

.containerWithBorder {
  border: 1px solid black;
  width: 90% !important;
  text-align: left !important;
  padding: 20px;
  margin-top: var(--container-margin);
  margin-bottom: 50px;
  color: black;
  background-color: white;
}

.containerWithBorder p {
  padding: 0;
}

.CEORow {
  padding: 20px 35px 20px 35px;
}

.advisorContainer {
  text-align: center !important;
  margin-bottom: 50px;
}

.advisorContainer label {
  font-size: inherit;
  color: black;
}

.communityEngagement {
  color: #ffff;
  background: #2D487C;
  width: 100%;
}

.communityEngagementLbl, .innovEduPlatformLbl {
  text-align: left;
  /* font-size: 2em;
  font-weight: 900; */
}

.communityEngagementCol, .innovEduPlatformCol {
  text-align: left;
  /* padding: 20px; */
  margin-bottom: 50px;
}

.communityEngagementRow, .innovEduPlatformRow {
  padding: 50px 100px 0px 100px;
}

.connectWithUsContainer, .teamContainer {
  background-color: #E4F0FE;
  color: black;
  margin: 50px 0px 50px 0px;
  width: 100%;
  padding-bottom: 50px;
}

.connectWithUsSection {
  padding: 20px
}

.innovEduPlatform {
  width: 90%;
}

.innovEduPlatform label, .innovEduPlatform p {
  color: black;
}

.iconsContainer img {
  max-width: 100px;
  max-height: 100px;
  padding: 1rem;
}

/* Make Card image responsive */

.col-6, .col-md-4 {
  margin-bottom: 20px;
}

#AboutContainer {
  background-image: linear-gradient(var(--color2) 0%, var(--color2) 5%, white 5%, white 100%);
  /* margin: 0; */
  height: auto;
}

#AboutContainer2 {
  background-image: linear-gradient(var(--color2) 0%, var(--color2) 20%, white 20%, white 100%);
  /* margin: 0; */
  height: auto;
}

#SpeakEng {
  color: black;
  background-color: var(--color1);
  /* padding-left: 15%; */
}

#SpeakEng p {
  margin-right: 0;
}

#aboutTeamVid {
  color: black
}

#aboutTeamVid h1 {
  /* padding-left: 3em; */
  padding: 0;
}

#aboutTeamVid p {
  /* padding-left: 3em; */
  padding: 0;
}

.center-vertical {
  position: relative;
  top: 100%;
  /* -webkit-transform: translateY(-50%); */
  -ms-transform: translateY(-50%);
  transform: translateY(10%);
  height: 90%;
}

.primaryElement .about{
  padding: 5em 5em !important;
}

.employee-info{
  background: #E4F0FE !important;
  border: none !important;
  padding: 1em;
}

.employee-info h1{
  padding: .5em 0em !important;
  font-size: 18pt;
}

.employee-info p{
  font-size: 16pt !important;
  margin-bottom: 0;
}

.employee-info h1>p{
  font-size: 20pt !important;
}

@media (max-width: 1400px) {
  .center-vertical {
    position: relative;
    top: 100%;
    /* -webkit-transform: translateY(-50%); */
    -ms-transform: translateY(-50%);
    transform: translateY(0%);
    height: 100%;
  }
}

@media (max-width: 768px) {
  .CEORow {
    padding: 10%;
  }
  .communityEngagementRow {
    padding: 5%;
  }
  .communityEngagementLbl{
    padding: 0 !important;
  }
  .communityEngagementCol p{
    padding: 0;
  }
  .sectionContainer {
    padding: 1.5em 1em 1.5em 1em !important;
    margin-top: 0 !important;
  }
  .sectionContainer h1{
    text-align: left;
  }
  .innovEduPlatformLbl{
    text-align: center !important;
  }
  .titleSection{
    text-align: left;
  }
  .col-12{
    display: inline-grid;
  }
  .img-center-size{
    padding: 1em 0em;
  }
  .primaryElement .about{
    padding: 0em 1em !important;
  }
  #aboutTeamVid h1{
    padding: 1em 0em 0em 0em;
  }
  #othalia-title{
    font-size: 24pt;
  }
}